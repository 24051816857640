(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@alifd/next")["Menu"], require("@alifd/next")["Icon"]);
	else if(typeof define === 'function' && define.amd)
		define([["@alifd/next","Menu"], ["@alifd/next","Icon"]], factory);
	else if(typeof exports === 'object')
		exports["RedHareEngineExt"] = factory(require("@alifd/next")["Menu"], require("@alifd/next")["Icon"]);
	else
		root["RedHareEngineExt"] = factory(root["Next"]["Menu"], root["Next"]["Icon"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__21__) {
return 