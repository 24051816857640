import { IPublicModelPluginContext } from '@redhare/lowcode-types';
import { plugins } from '@redhare/lowcode-engine'
import { setterMap } from './setter';
import { pluginMap } from './plugin';
import './index.less';

// 注册默认的 setters
export const setterRegistry = (ctx: IPublicModelPluginContext) => {
  return {
    init() {
      const { setters, skeleton } = ctx;
      // 注册 setterMap
      setters.registerSetter(setterMap as any);
      // 注册事件绑定面板
      skeleton.add({
        area: 'centerArea',
        type: 'Widget',
        content: pluginMap.EventBindDialog,
        name: 'eventBindDialog',
        props: {},
      });

      // 注册变量绑定面板
      skeleton.add({
        area: 'centerArea',
        type: 'Widget',
        content: pluginMap.VariableBindDialog,
        name: 'variableBindDialog',
        props: {},
      });
    },
  };
};

setterRegistry.pluginName = '___setter_registry___';

export default setterRegistry